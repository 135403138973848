import {
  trigger,
  transition,
  style,
  animate,
  query,
  group,
} from '@angular/animations';

export const routeTransition = trigger('routeTransition', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({
          opacity: 0,
          scale: 0.9,
        }),
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        animate(
          '250ms',
          style({
            opacity: 0,
            scale: 0.9,
          })
        ),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        animate(
          '250ms',
          style({
            opacity: 1,
            scale: 1,
          })
        ),
      ],
      { optional: true }
    ),
  ]),
]);
