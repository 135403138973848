import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nServiceService } from './shared/shared-services/i18n-service/i18n-service.service';
import { ActivatedRoute } from '@angular/router';
import { routeTransition } from './shared/components/route-animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [routeTransition]
})
export class AppComponent {
  constructor(
    private readonly translate: TranslateService,
    private i18nService: I18nServiceService,
    protected route: ActivatedRoute
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  public changeLanguage(language: string): void {
    this.i18nService.changeLanguage(language);
  }
  title = 'Shohay';
}
